import React from 'react';
import styled from 'styled-components';

interface CSSProps {
  $width: string;
}

const InfoBitContainer = styled.div<CSSProps>`
  padding: ${({ theme }) => theme.sizes.md};
  flex: 0 1 ${({ $width }) => $width};
  min-width: 13rem;

  .header {
    background: none;
    color: ${({ theme }) => theme.textColorSecondary};
    font-size: 0.875rem;
    font-weight: normal;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
  }

  .info {
    font-family: 'Roboto Mono', monospace;
    word-break: break-word;
    color: ${({ theme }) => theme.textColor};
    position: relative;
  }
`;

export interface InfoBitProps {
  header: string;
  info?: string;
  width: string;
  icon?: React.ReactFragment;
  className?:string;
}

const InfoBit: React.FC<InfoBitProps> = ({ header, info, width, icon, className }) => {
  return (
    <InfoBitContainer className={className} $width={width}>
      <p className='header'>{header}</p>
      <span className='info'>{info} {icon}</span>
    </InfoBitContainer>
  );
};

export default InfoBit;
