import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Form, FormInputProps as SURFormInputProps } from 'semantic-ui-react';

import { InputStyle } from './ThemedInput';

const StyledFormInput = styled(Form.Input)`
  display: flex;
  flex-flow: column;
  flex: ${({ $fluid }) => $fluid ? 1 : 0};

  &&& input {
    margin: 0;
    ${InputStyle} /* stylelint-disable-line value-keyword-case */
    width: 100%;
    flex: 0 auto;
  }

  &&& label {
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    line-height: 1.4285em;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textColorSecondary};
    width: 100%;
    flex: 0 auto;
  }

  &&& .prompt.label {
    background-color: ${({ theme }) => theme.appBg} !important;
    border-color: ${({ theme }) => theme.appBorderColor};
    padding: ${({ theme }) => theme.sizes.sm} ${({ theme }) => theme.sizes.sm} ${({ theme }) => theme.sizes.sm} ${({ theme }) => theme.sizes.sm};
  }
`;

export interface FormInputProps extends SURFormInputProps {
  type?: 'text' | 'number';
  validateOnBlur?: boolean;
  value?: string;
  fluid?: boolean;
  name?: string;
  validationFn?: (name: string | undefined, value: string | undefined) => boolean | string;
}

const FormInput: React.FC<FormInputProps> = ({ value, name, validationFn, validateOnBlur = true, type = 'text', fluid = false, ...props }) => {
  const [validationError, setValidationError] = useState<boolean | string | undefined>(undefined);

  useEffect(() => {
    if (!validationFn || typeof validationFn !== 'function') {
      return;
    }

    // Always validate when value changes if we don't do it on blur
    if (!validateOnBlur) {
      const errorState = validationFn(name, value);
      setValidationError(errorState);
    } else if (validationError) {
      // Otherwise validate on changes if there is an error so it can be cleared
      const errorState = validationFn(name, value);
      setValidationError(errorState);
    }
  }, [name, value, validationError, validationFn, validateOnBlur]);

  const handleBlur = () => {
    if (!validationFn || typeof validationFn !== 'function') {
      return;
    }

    if (validateOnBlur) {
      const errorState = validationFn(name, value);
      setValidationError(errorState);
    }
  };

  return (
    <StyledFormInput value={value} name={name} error={validationError} onBlur={handleBlur} control='input' type={type} $fluid={fluid} {...props} />
  );
};

export default FormInput;
