import React, { useState } from 'react';
import { Loader, PaginationProps } from 'semantic-ui-react';

import Pagination from './ThemedPagination';
import TableRow from './TableRow';

import { StyledDimmable, StyledDimmer, StyledTable, StyledTableHeader, StyledHeaderCell, StyledBody, StyledCell } from './ThemedTable';

import type { TableRowDataType, TableKeyType, TableRowType, TableSortDirectionType } from './ThemedTable';

export type ServerSideTableRowDataType = TableRowDataType;
export type ServerSideTableKeyType = TableKeyType;
export type ServerSideTableRowType = TableRowType;
export type ServerSideTableSortDirectionType = TableSortDirectionType;

export type ServerSideTableColumnOrderType = {
  asc: string;
  desc: string;
};

export type ServerSideTableColumnType = {
  name: string;
  key: ServerSideTableKeyType;
  order?: ServerSideTableColumnOrderType;
  width?: string;
  sortFn?: (direction: 'asc' | 'desc', key: ServerSideTableKeyType) => (a: ServerSideTableKeyType, b: ServerSideTableKeyType) => 1 | -1 | 0;
};

export interface ServerSideTableProps {
  tableDataColumns: ServerSideTableColumnType[];
  tableDataRows: ServerSideTableRowType[];
  emptyText?: string;
  sortable?: boolean;
  defaultSort?: ServerSideTableSortDirectionType;
  loading?: boolean;
  loadingMessage?: string;
  numPages: number;
  activePage: number;
  onColumnOrderChange: (e: React.SyntheticEvent<HTMLElement, MouseEvent>, order: ServerSideTableSortDirectionType, columnOrder: string | undefined) => void;
  onPageChange: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: PaginationProps) => void;
}

const ThemedServerSideTable: React.FC<ServerSideTableProps> = ({ tableDataRows, tableDataColumns, emptyText = 'No data', sortable, onColumnOrderChange, onPageChange, numPages, defaultSort, loading, loadingMessage = 'loading', activePage }) => {
  const [currentSortDirection, setCurrentSortDirection] = useState(defaultSort);

  const handleColumnOrderChange = (e: React.SyntheticEvent<HTMLElement, MouseEvent>, column: ServerSideTableColumnType) => {
    if (!sortable) {
      return;
    }

    const currentlySortedThisColumn: boolean = currentSortDirection?.key === column.key;
    let _sortDirection: 'asc' | 'desc' = 'asc';

    if (currentlySortedThisColumn) {
      _sortDirection = currentSortDirection?.direction === 'asc' ? 'desc' : 'asc';
    }

    setCurrentSortDirection({
      key: column.key,
      direction: _sortDirection
    });

    onColumnOrderChange(e, { key: column.key, direction: _sortDirection }, column.order ? column.order[_sortDirection] : undefined);
  };

  const handlePageChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: PaginationProps) => {
    onPageChange(e, page);
  };

  const getCells = (row: ServerSideTableRowDataType, successful: boolean) => {
    return Object.entries(row)
      .map(([, value], i) =>
        <StyledCell
          key={i}
          $successful={successful}
          title={typeof value === 'string' ? value : ''}
        >
          {value}
        </StyledCell>);
  };

  const handleRowClick = (rowKey: string | number) => {
    console.log('handleRowClick: ', rowKey);
  };

  return (
    <>
      <StyledDimmable>
        {loading && <StyledDimmer active={loading}><Loader active={loading} inline='centered'>{loadingMessage}</Loader></StyledDimmer>}
        {(!loading && tableDataRows && tableDataRows.length === 0) && <StyledDimmer active>{emptyText}</StyledDimmer>}
        <StyledTable unstackable sortable={sortable}>
          <StyledTableHeader>
            <TableRow key='headerrow'>
              {tableDataColumns.map(column => (
                <StyledHeaderCell
                  key={column.name}
                  onClick={sortable ? (e: React.SyntheticEvent<HTMLElement, MouseEvent>) => handleColumnOrderChange(e, column) : (e: React.SyntheticEvent<HTMLElement, MouseEvent>) => e.preventDefault()}
                  $sortable={sortable}
                  className={[
                    sortable ? 'sortable' : '',
                    currentSortDirection?.key === column.key && currentSortDirection.direction === 'asc' ? 'ascending sorted' : '',
                    currentSortDirection?.key === column.key && currentSortDirection.direction === 'desc' ? 'descending sorted' : ''
                  ].join(' ')}
                >
                  {column.name}
                </StyledHeaderCell>
              ))}
            </TableRow>
          </StyledTableHeader>
          {(tableDataRows && tableDataRows.length > 0 && !loading) &&
            <StyledBody>
              {tableDataRows.map(row => {
                const { successful = true } = row;

                return (
                  <TableRow
                    key={row.key.toString()}
                    successful={successful}
                    onClick={() => handleRowClick(row.key)}
                  >
                    {getCells(row.data, successful)}
                  </TableRow>
                );
              }
              )}
            </StyledBody>}
        </StyledTable>
      </StyledDimmable>
      {(numPages > 1) &&
        <Pagination activePage={activePage} totalPages={numPages} onPageChange={handlePageChange} />}
    </>
  );
};

export default ThemedServerSideTable;
