import React from 'react';
import { Select, SelectProps as SURSelectProps } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import Label from './ThemedLabel';

interface SelectCSSProps {
  $fluid?: boolean;
  value: string | undefined | null;
}

export const SelectStyle = css<SelectCSSProps>`
  &&&.ui.selection.dropdown {
    align-items: center;
    background: ${({ theme }) => theme.appBg};
    border-radius: ${({ theme }) => theme.inputBorderRadius};
    border: 2px solid ${({ theme }) => theme.inputBorder};
    color: ${({ theme }) => theme.inputText};
    display: flex;
    flex: ${({ $fluid }) => $fluid ? 1 : 0};
    height: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
    padding: 0.5rem 2rem 0.5rem 0.5rem;

    &:focus {
      background: none;
      color: ${({ theme }) => theme.inputText};
      border: ${({ theme }) => `2px solid ${theme.inputBorderSecondary}`};
      outline: none;
    }

    & > .text {
      color: ${({ theme, value }) => !value ? theme.inputPlaceholderText : theme.textColorSelected};
    }

    &.active {
      & > .text {
        color: ${({ theme }) => theme.inputText};
      }

      & > i.icon:not(.clear) {
        transform: scaleY(-1);
        top: 0.6rem;
      }
    }

    .menu {
      background: ${({ theme }) => theme.appBg};
      border-top: 2px solid ${({ theme }) => theme.inputBorder} !important;
      border: 2px solid ${({ theme }) => theme.inputBorder};
      margin: 0 -2px;
      width: calc(100% + 4px);
      color: inherit;

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colorPrimary};
      }

      &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0.1);
      }

      .item {
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .text {
          color: ${({ theme }) => theme.textColorClickable};
        }

        &.selected {
          .text {
            color: ${({ theme }) => theme.textColorSelected};
          }
        }

        &:hover {
          background: ${({ theme }) => theme.colorSecondary};

          .text {
            color: ${({ theme }) => theme.textColorSelected};
          }
        }
      }
    }

    .dropdown.icon {
      ::before {
        width: 1.25rem;
        height: 1.25rem;
        display: block;
      }

      &.clear {
        color: ${({ theme }) => theme.textColorClickable};
        top: ${({ theme }) => theme.sizes.sm};
        right: ${({ theme }) => theme.sizes.sm};
      }

      &:not(.clear) {
        top: 0.313rem;

        ::before {
          content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 24 24" fill="none" stroke="${({ theme }) => theme.colorPrimary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
        }
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  ${SelectStyle}  /* stylelint-disable-line value-keyword-case */
`;

interface SelectContainerCSSProps {
  $fluid?: boolean;
}

const SelectContainer = styled.div<SelectContainerCSSProps>`
  display: flex;
  flex-flow: column;
  flex: ${({ $fluid }) => $fluid ? 1 : 0};

  input {
    margin: 0;
  }
`;

export type SelectValueType = (string | number | boolean)[] | string | number | boolean | undefined;

export interface SelectProps extends SURSelectProps {
  label?: string;
  required?: boolean;
  name?: string;
  fluid?: boolean;
}

const ThemedSelect: React.FC<SelectProps> = ({ label, required, name, fluid = false, ...props }) => (
  <SelectContainer $fluid={fluid}>
    {label && <Label name={name} label={label} required={required} />}
    <StyledSelect
      $fluid={fluid}
      {...props}
    />
  </SelectContainer>
);

export default ThemedSelect;
