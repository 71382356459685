import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableCell,
  Dimmer,
  DimmerDimmable,
  Loader,
  CheckboxProps,
  DimmerDimmableProps,
  PaginationProps
} from 'semantic-ui-react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';

import Checkbox from './ThemedCheckbox';
import Pagination from './ThemedPagination';
import TableRow from './TableRow';

export type TableKeyType = string | number;

export type TableColumnType = {
  name: string;
  key: TableKeyType;
  width?: string;
  sortFn?: (direction: 'asc' | 'desc', key: TableKeyType) => (a: TableRowType, b: TableRowType) => 1 | -1 | 0;
};

export type TableRowDataType = {
  [key: string]: string | React.ReactNode;
};

export type TableRowSortDataType = {
  [key: string]: string;
};

export type TableRowType = {
  key: TableKeyType;
  successful?: boolean;
  sortData?: TableRowSortDataType;
  data: TableRowDataType;
};

export type TableSortDirectionType = {
  key: TableKeyType | undefined;
  direction: 'asc' | 'desc';
};

export interface TableProps {
  tableDataColumns: TableColumnType[];
  tableDataRows: TableRowType[];
  checkedRows?: TableKeyType[];
  selectable?: boolean;
  rowsPerPage?: number;
  sortable?: boolean;
  loading?: boolean;
  loadingMessage?: string;
  emptyText?: string;
  defaultSort?: string;
  defaultSortDir?: 'asc' | 'desc';
  setQueryString?: boolean;
  checkbox?: boolean;
  onSelectAll?: (el: CheckboxProps) => void;
  onSelectSingle?: (el: CheckboxProps, i: number, row: TableRowType) => void;
  onDataSorted?: (sorted: TableRowType[]) => void;
}

export const StyledDimmable = styled(DimmerDimmable)`
  height: 100%;

  &&& .ui.dimmer {
    z-index: 5;
    background-color: ${({ theme }) => theme.appBgLight};

    & .content {
      color: ${({ theme }) => theme.textColor};
      background: none;
    }
  }
`;

// TODO: disabled ruels
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export const StyledDimmer: StyledComponent<React.ComponentClass<DimmerDimmableProps, any>, any, {}, never> = styled(
  Dimmer
)`
  &&& .ui.loader {
    color: ${({ theme }) => theme.textColor};

    &::before {
      color: ${({ theme }) => theme.textColor};
    }

    &::after {
      border-color: ${({ theme }) => theme.textColor} transparent transparent;
    }
  }
`;

// TODO: disabled ruels
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export const StyledTable: StyledComponent<typeof Table, any, {}, never> = styled(Table)`
  &&& {
    background: none;
    height: 97%;
    color: ${({ theme }) => theme.textColor};
    padding: ${({ theme }) => theme.sizes.md};
    table-layout: fixed;
    margin: 0;
    border: none;
  }
`;

export const StyledBody = styled(TableBody)`
  &&& {
    font-family: ${({ theme }) => theme.fontSecondary};
    display: block;
    height: 97%;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colorPrimary};
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0.1);
    }

    &&& td {
      &.checkBox {
        width: 3.325rem;
      }
    }

    & tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
`;

export const StyledTableHeader = styled(TableHeader)`
  &&& {
    display: table;
    width: 100%;
    table-layout: fixed;

    &&& tr {
      background: ${({ theme }) => theme.appBg};
    }
  }
`;

interface HeaderCellCSSProps {
  $sortable: boolean;
}

export const StyledHeaderCell = styled(TableHeaderCell)<HeaderCellCSSProps>`
  &&& {
    background: none;
    color: ${({ theme }) => theme.textColorSecondary};
    font-size: 0.875rem;
    font-weight: normal;
    padding: ${({ theme }) => theme.sizes.sm};
    text-transform: uppercase;
  }

  &&&.checkBox {
    width: 3.325rem;
  }

  &&&.sortable,
  &&&.sortable.sorted {
    background: none;
    border-left: none;
    color: ${({ theme }) => theme.textColorSecondary};
    font-size: 0.875rem;
    font-weight: normal;
    padding: ${({ theme }) => theme.sizes.sm};
    text-transform: uppercase;

    &:hover {
      background: none;
      color: ${({ theme, $sortable }) => ($sortable ? theme.textColor : theme.textColorSecondary)};
    }
  }
`;

export interface CellCSSProps {
  $successful?: boolean;
}

export const StyledCell = styled(TableCell)<CellCSSProps>`
  &&& {
    color: ${({ theme, $successful }) => ($successful ? 'inherit' : theme.textColorError)};
    height: 3.375rem;
    overflow: hidden;
    padding: ${({ theme }) => theme.sizes.sm};
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
}

const DEFAULT_PAGE = 1;

const ThemedTable: React.FC<TableProps> = ({
  tableDataColumns,
  tableDataRows,
  rowsPerPage = 7,
  selectable = false,
  sortable,
  emptyText,
  defaultSort,
  defaultSortDir = 'asc',
  onDataSorted,
  setQueryString = true,
  checkbox,
  checkedRows,
  onSelectAll,
  onSelectSingle,
  loading,
  loadingMessage = 'Loading...'
}) => {
  const [allRows, setAllRows] = useState<TableRowType[]>([]);
  const previousTableDataRows = usePrevious(tableDataRows);
  const [pagedRows, setPagedRows] = useState<TableRowType[]>([]);
  const [currentSortDirection, setCurrentSortDirection] = useState<TableSortDirectionType>({
    key: defaultSort,
    direction: defaultSortDir
  });
  const previousSort = usePrevious(currentSortDirection);
  const [numPages, setNumPages] = useState(1);
  const [activePage, setActivePage] = useState<number>(DEFAULT_PAGE);
  const [allChecked, setAllChecked] = useState<boolean | undefined>(false);

  const history = useHistory();
  const location = useLocation();

  const setCurrentPage = useCallback(
    (page: number | string = DEFAULT_PAGE) => {
      if (setQueryString) {
        const query = new URLSearchParams(history.location.search);
        query.set('activePage', String(page));
        history.replace({ ...history.location, search: query.toString() });
      } else {
        const activePage = typeof page !== 'string' ? page : Number.parseInt(page);
        setActivePage(activePage);
      }
    },
    [history, setQueryString]
  );

  const sortTable = useCallback(
    (tableDataRows: TableRowType[]): boolean => {
      const sortKey = currentSortDirection.key;
      const column = tableDataColumns.find((a) => a.key === sortKey);

      if (column === undefined) {
        // No sorting to do. Bail early and set rows as they arrived.
        setAllRows(tableDataRows);
        return false;
      }

      const defaultSortFn = (a: TableRowType, b: TableRowType) => {
        const aval = a.sortData && a.sortData[column.key] ? a.sortData[column.key] : a.data[column.key];
        const bval = b.sortData && b.sortData[column.key] ? b.sortData[column.key] : b.data[column.key];

        if (aval == null || bval == null) {
          return 0;
        }

        if (currentSortDirection.direction === 'asc') return aval > bval ? 1 : -1;
        else return bval > aval ? 1 : -1;
      };

      const sortFn =
        typeof column.sortFn === 'function' ? column.sortFn(currentSortDirection.direction, column.key) : defaultSortFn;
      const sorted = tableDataRows.slice(0).sort(sortFn);

      setAllRows(sorted);

      if (onDataSorted) {
        onDataSorted(sorted);
      }

      return true;
    },
    [currentSortDirection, tableDataColumns, onDataSorted]
  );

  useEffect(() => {
    if (!tableDataRows || tableDataRows.length === 0) {
      setAllRows([]);
      return;
    }

    // If the sort direction changed or the tableDataRows changed sort the table
    // And set data to display to be the sorted rows
    if (previousSort !== currentSortDirection || tableDataRows !== previousTableDataRows) {
      sortTable(tableDataRows);
    }
  }, [tableDataRows, currentSortDirection, sortTable, previousSort, previousTableDataRows]);

  useEffect(() => {
    const _rows = rowsPerPage || allRows.length;
    const _pagedRows = allRows.slice((activePage - 1) * _rows, activePage * _rows);
    setPagedRows(_pagedRows);
  }, [allRows, rowsPerPage, activePage]);

  useEffect(() => {
    if (!pagedRows || pagedRows.length === 0) {
      setAllChecked(false);
      return;
    }

    if (checkedRows) {
      const allChecked = pagedRows.every((i) => checkedRows.includes(i.key));

      setAllChecked(allChecked);
    }
  }, [checkedRows, pagedRows]);

  useEffect(() => {
    if (setQueryString && location) {
      const params = queryString.parse(location.search);
      if (typeof params.activePage === 'string') {
        setActivePage(Number.parseInt(params.activePage));
      } else {
        setActivePage(DEFAULT_PAGE);
      }
    }
  }, [location, setQueryString]);

  useEffect(() => {
    if (rowsPerPage === 0) {
      setNumPages(1);
    } else {
      if (tableDataRows) {
        setNumPages(Math.ceil(tableDataRows.length / rowsPerPage));
      } else {
        setNumPages(0);
      }
    }
  }, [tableDataRows, rowsPerPage]);

  const handleSort = (column: TableColumnType) => {
    const currentlySortedThisColumn: boolean = currentSortDirection?.key === column.key;
    let _sortDirection: 'asc' | 'desc' = 'asc';

    if (currentlySortedThisColumn) {
      _sortDirection = currentSortDirection?.direction === 'asc' ? 'desc' : 'asc';
    }

    setCurrentSortDirection({
      key: column.key,
      direction: _sortDirection
    });
  };

  const onRowCheckboxSelect = (el: CheckboxProps, i: number, row: TableRowType) => {
    onSelectSingle && onSelectSingle(el, i, row);
  };

  const getCells = (row: TableRowType, successful: boolean, i: number) => {
    const cells = [];
    const data = row.data;

    if (checkbox) {
      cells.push(
        <StyledCell key={`checkboxrow${i}`} $successful={successful} className='checkBox'>
          <Checkbox
            onChange={(_event, el) => {
              onRowCheckboxSelect(el, i, row);
            }}
            checked={checkedRows?.indexOf(row.key) !== -1}
          />
        </StyledCell>
      );
    }

    if (tableDataColumns.length > 0) {
      tableDataColumns.forEach((col, i) => {
        const value = data[col.key];
        cells.push(
          <StyledCell key={i} width={col.width} $successful={successful} title={typeof value === 'string' ? value : ''}>
            {value}
          </StyledCell>
        );
      });
    } else {
      Object.entries(row).forEach(([, value], i) => {
        cells.push(
          <StyledCell key={i} $successful={successful} title={typeof value === 'string' ? value : ''}>
            {value}
          </StyledCell>
        );
      });
    }

    return cells;
  };

  const selectAll = (_e: React.FormEvent<HTMLInputElement>, el: CheckboxProps) => {
    setAllChecked(el.checked);
    onSelectAll && onSelectAll(el);
  };
  const onPageChange = (_: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: PaginationProps) => {
    setCurrentPage(page.activePage);
  };
  return (
    <>
      <StyledDimmable>
        {loading && (
          <StyledDimmer active={loading}>
            <Loader active={loading} inline='centered'>
              {loadingMessage}
            </Loader>
          </StyledDimmer>
        )}
        {!loading && tableDataRows && tableDataRows.length === 0 && <StyledDimmer active>{emptyText}</StyledDimmer>}
        <StyledTable unstackable sortable={sortable}>
          <StyledTableHeader>
            <TableRow key='headerrow'>
              {checkbox && (
                <StyledHeaderCell key='checkallbox' className='checkBox'>
                  <Checkbox onChange={selectAll} checked={allChecked} />
                </StyledHeaderCell>
              )}
              {tableDataColumns.map((column) => (
                <StyledHeaderCell
                  key={column.name}
                  width={column.width}
                  onClick={
                    sortable
                      ? () => handleSort(column)
                      : (e: React.SyntheticEvent<HTMLElement, MouseEvent>) => e.preventDefault()
                  }
                  $sortable={sortable}
                  className={[
                    sortable ? 'sortable' : '',
                    currentSortDirection?.key === column.key && currentSortDirection?.direction === 'asc'
                      ? 'ascending sorted'
                      : '',
                    currentSortDirection?.key === column.key && currentSortDirection?.direction === 'desc'
                      ? 'descending sorted'
                      : ''
                  ].join(' ')}>
                  {column.name}
                </StyledHeaderCell>
              ))}
            </TableRow>
          </StyledTableHeader>
          {pagedRows && (
            <StyledBody>
              {pagedRows.map((row: TableRowType, i: number) => {
                const { successful = true } = row;

                return (
                  <TableRow
                    key={row.key.toString()}
                    successful={successful}
                    selectable={selectable}
                    >
                    {getCells(row, successful, i)}
                  </TableRow>
                );
              })}
            </StyledBody>
          )}
        </StyledTable>
      </StyledDimmable>
      {numPages > 1 && <Pagination activePage={activePage} totalPages={numPages} onPageChange={onPageChange} />}
    </>
  );
};

export default ThemedTable;
