import React from 'react';
import styled from 'styled-components';

const HeaderRowDiv = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.md};

  button {
    margin-left: auto;
  }

  .buttonContainer {
    justify-content: flex-end;
    width: 100%;
    display: flex;

    > button {
      margin-left: 5px;
    }
  }

  a {
    color: ${({ theme }) => theme.textColorLink};
    text-decoration: none;
    margin-left: auto;

    &:hover {
      color: ${({ theme }) => theme.textColorLinkHover};
      text-decoration: none;
    }
  }
`;

export interface HeaderRowProps {
  children: React.ReactNode[] | React.ReactNode;
}

const HeaderRow: React.FC<HeaderRowProps> = ({ children }) => <HeaderRowDiv>{children}</HeaderRowDiv>;

export default HeaderRow;
