import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import type { PopupProps } from 'semantic-ui-react';

const StyledPopup = styled(Popup)`
  &&& {
    & > div.content {
      background: ${({ theme }) => theme.appSecondaryBg};
      color: ${({ theme }) => theme.textColor};
    }

    background: ${({ theme }) => theme.appSecondaryBg};

    &.bottom,
    &.top {
      &::before {
        background: ${({ theme }) => theme.appSecondaryBg};
      }
    }
  }
`;

const ThemedPopup: React.FC<PopupProps> = ({ ...props }) => <StyledPopup {...props} />;

export default ThemedPopup;

export type { PopupProps };
