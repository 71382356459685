import React from 'react';
import styled from 'styled-components';

import { getLinkIcon } from './TableLink';
import type { TableIconType } from '../Common/Constant/IconType';

const StyledTableLink = styled.div`
  display: flex;
  align-items: center;
`;

interface CSSProps {
  $successful?: boolean;
  $strikeThrough?: boolean;
  $fontSize?: string;
}

const StyledText = styled.span<CSSProps>`
  &&& {
    color: ${({ $successful, theme }) => $successful ? theme.textColor : theme.textColorError};
    text-decoration: ${({ $strikeThrough }) => $strikeThrough ? 'line-through' : 'none'};
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ $fontSize }) => $fontSize};
  }
`;

export interface TableTextProps {
  strikeThrough?: boolean;
  iconType?: TableIconType;
  text?: string;
  successful?: boolean;
  onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  fontSize?: string;
}

const TableText: React.FC<TableTextProps> = ({ strikeThrough, iconType, text, onClick, successful = true, fontSize = '1rem' }) => {
  return (
    <StyledTableLink>
      {iconType !== undefined &&
        getLinkIcon(iconType, successful, onClick)}
      <StyledText title={text} $strikeThrough={strikeThrough} $successful={successful} $fontSize={fontSize}>
        {text}
      </StyledText>
    </StyledTableLink>
  );
};

export default TableText;
