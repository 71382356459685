import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TableRow, TableRowProps as SURTableRowProps } from 'semantic-ui-react';
import { TableKeyType } from './ThemedTable';

interface CSSProps {
  $successful: boolean;
  $selected: boolean;
  $selectable: boolean;
}

const StyledRow = styled(TableRow)<CSSProps>`
  &&& {
    color: ${({ theme, $successful }) => $successful ? 'inherit' : theme.textColorError};
    background: ${({ $selected }) => $selected ? 'rgba(19, 189, 141, 0.2)' : ''};

    &:nth-child(odd) {
      background: ${({ theme, $selected }) => !$selected ? theme.colorSecondary : ''};
    }

    &:hover {
      background: ${({ $selectable, $selected }) => $selectable && !$selected ? 'rgba(255, 255, 255, 0.05)' : ''};
    }

    td,
    th {
      &:first-child {
        padding-left: ${({ theme }) => theme.sizes.md};
      }

      &:last-child {
        padding-right: ${({ theme }) => theme.sizes.md};
      }
    }
  }
`;

export interface TableRowProps extends SURTableRowProps {
  children: React.ReactNode[] | React.ReactNode;
  successful?: boolean;
  selectable?: boolean;
  selected?: boolean;
  rowKey?: TableKeyType;
  key: string;
  onClick?: (key: string) => void;
}

const ThemedTableRow: React.FC<TableRowProps> = ({ children, successful = true, selectable = false, selected = false, onClick, ...props }) => {
  const [rowSelected, setSelected] = useState(false);

  const handleClick = () => {
    if (!selectable) {
      return;
    }

    setSelected(!rowSelected);

    return onClick ? onClick(props.key) : undefined;
  };

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  return (
    <StyledRow
      $successful={successful}
      $selectable={selectable}
      $selected={rowSelected}
      onClick={() => handleClick()}
      {...props}
    >
      {children}
    </StyledRow>
  );
};

export default ThemedTableRow;
