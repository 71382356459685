import { Theme } from '../types/styles';
import { ThemeTypes } from './Constant/ThemeTypes';

export const defaultSizes = {
  sm: '0.5rem',
  md: '1rem',
  lg: '2rem'
};
export const lightTheme: Theme = {
  name: ThemeTypes.LIGHT,
  appBg: '#FFFFFF',
  appBgLight: '#F5F5F57F',
  appBorderColor: 'rgba(0,0,0,0.3)',
  appSecondaryBg: '#FFFFFF7F',
  bg: '#fff',
  buttonBorder: 'rgb(239,124,0)',
  buttonInactiveBorder: 'rgba(0,0,0,0.6)',
  buttonInactiveText: 'rgba(0,0,0,0.6)',
  buttonText: 'rgb(239,124,0)',
  buttonTextSecondary: '#FFFFFF',
  colorPrimary: 'rgb(239,124,0)',
  colorSecondary: 'rgba(0,0,50,0.02)',
  fontBase: 'Heebo, sans-serif',
  fontSecondary: "'Roboto Mono', monospace;",
  headerHeight: '5.813rem',
  inputBorder: 'rgba(0,0,0,0.6)',
  inputBorderSecondary: 'rgba(0,0,0,0.8)',
  inputBorderRadius: '0.188rem',
  inputInactiveBackground: 'rgba(0,0,0,0.05)',
  inputInactiveBorder: 'rgba(0,0,0,0.3)',
  inputInactiveText: 'rgba(0,0,0,0.3)',
  inputPlaceholderText: 'rgba(0,0,0,0.3)',
  inputText: 'rgb(0,0,0)',
  mapBg: '#FFFFFF',
  sizes: defaultSizes,
  textColor: 'rgba(0,0,0,1)',
  textColorClickable: 'rgb(239,124,0)',
  textColorError: 'rgb(248,65,12)',
  textColorErrorHover: 'rgb(248,65,12,0.8)',
  textColorErrorSecondary: 'rgba(248, 65, 12, 0.6)',
  textColorLink: 'rgb(0,90,255)',
  textColorLinkHover: 'rgba(0,90,255,0.8)',
  textColorSecondary: 'rgba(110,110,110,1)',
  textColorSelected: 'rgb(0,0,0)'
};

export const darkTheme: Theme = {
  name: ThemeTypes.DARK,
  appBg: '#1E1D22',
  appBgLight: '#1E1D227F',
  appBorderColor: 'rgba(255,255,255,0.3)',
  appSecondaryBg: '#222126',
  bg: '#fff',
  buttonBorder: 'rgb(239,124,0)',
  buttonInactiveBorder: 'rgba(255,255,255,0.6)',
  buttonInactiveText: 'rgba(255,255,255,0.6)',
  buttonText: 'rgb(239,124,0)',
  buttonTextSecondary: '#FFFFFF',
  colorPrimary: 'rgb(239,124,0)',
  colorSecondary: 'rgba(0,0,0,0.15)',
  fontBase: 'Heebo, sans-serif',
  fontSecondary: "'Roboto Mono', monospace;",
  headerHeight: '5.813rem',
  inputBorder: 'rgba(255,255,255,0.6)',
  inputBorderSecondary: 'rgba(255,255,255,0.8)',
  inputBorderRadius: '0.188rem',
  inputInactiveBackground: 'rgba(255,255,255,0.05)',
  inputInactiveBorder: 'rgba(255,255,255,0.3)',
  inputInactiveText: 'rgba(255,255,255,0.3)',
  inputPlaceholderText: 'rgba(255,255,255,0.3)',
  inputText: 'rgb(255,255,255)',
  mapFilter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(39deg) brightness(104%) contrast(102%)',
  mapBg: '#393940',
  sizes: defaultSizes,
  textColor: 'rgba(255,255,255,0.9)',
  textColorClickable: 'rgb(239,124,0)',
  textColorError: 'rgb(248,65,12)',
  textColorErrorHover: 'rgb(248,65,12,0.8)',
  textColorErrorSecondary: 'rgba(248, 65, 12, 0.6)',
  textColorLink: 'rgb(0,190,233)',
  textColorLinkHover: 'rgba(0,190,233,0.8)',
  textColorSecondary: 'rgba(255,255,255,0.6)',
  textColorSelected: 'rgb(255,255,255)'
};

export const themes = {
  dark: darkTheme,
  light: lightTheme
};
