import { DateTime } from 'luxon';
import * as FileSaver from 'file-saver';
import { TableColumnType, TableRowType } from '..';
import { Column, TableColumnProperties, Workbook } from 'exceljs';
import { ReactNode } from 'react';

export const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const getExcelRowsFromTableData = (tableDataColumns:TableColumnType[], tableDataRows?:TableRowType[], successfulColumn = false):ReactNode[] => {
  if (tableDataRows) {
    return tableDataRows.map(row => {
      const newRow = [] as ReactNode[];

      tableDataColumns.forEach((col) => {
        let rowData = (row.sortData && row.sortData[col.key]) ? row.sortData[col.key] : row.data[col.key];

        // HACK: Exclude sortData from DateTime ranges
        if (DateTime.isDateTime(rowData) && (row.data[col.key] as string)?.includes('-')) {
          rowData = row.data[col.key];
        }

        newRow.push(rowData);
      });

      if (successfulColumn) {
        newRow.push(row.successful);
      }

      return newRow;
    });
  }
  return [] as ReactNode[];
};

export const getExcelColumnsFromTableData = (tableDataColumns:TableColumnType[], successfulColumn = false):TableColumnProperties[] => {
  const columns = tableDataColumns.map((col) => (
    {
      name: col.name,
      filterButton: true

    } as TableColumnProperties
  ));

  if (successfulColumn) {
    columns.push({ name: 'Successful', filterButton: true });
  }

  return columns;
};

/**
 * Calulate the size of the largest value and adapt the columns accordingly. Multiply by 1.5 to make columns feel lighter.
 * @param {TableColumnType[]} tableDataColumns - an array of TableColumnTypes
 * @param {ReactNode[]} data - an array of reactnodes, containing data to export
 * @returns {Column[]} - An array of columns
 */
export const getExcelColumnFormatsFromTableData = (tableDataColumns:TableColumnType[], data:ReactNode[]):Column[] => {
  const columns = tableDataColumns.map((_, index) => ({
    width: getMaxWidth(data, index) * 1.5
  } as Column));
  return columns;
};

export const writeWorkbook = (wb:Workbook, fileNameToSaveAs:string) => {
  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: excelFileType });
    FileSaver.saveAs(blob, fileNameToSaveAs);
  });
};

/**
 * Get the max content length of the content of an table dataset
 * @param {ReactNode[]} data - an array of react nodes
 * @param {number} index - current index
 * @returns {number} - max length of content
 */
const getMaxWidth = (data:ReactNode[], index:number):number => {
  const largestElement = ((data as string[]).reduce((prev, current) => {
    return (prev[index] || '').length > (current[index] || '').length ? prev : current;
  }));
  return (largestElement[index] || '').length;
};
