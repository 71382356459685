import React, { useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-feather';

import { InputStyle } from './ThemedInput';
import Label from './ThemedLabel';

import 'react-datepicker/dist/react-datepicker.css';

interface CSSProps {
  $hasLabel?: boolean;
  $disabled?: boolean;
  $selected?: boolean;
}

const DatePickerContainer = styled.div<CSSProps>`
  &&& {
    position: relative;
    display: inline-block;
    flex: 1;
    min-width: 172px;

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      border-bottom-color: ${({ theme }) => theme.appBorderColor};
    }

    .react-datepicker-popper {
      z-index: 10;
    }

    .react-datepicker {
      background: ${({ theme }) => theme.appSecondaryBg};
      border: 1px solid ${({ theme }) => theme.appBorderColor};
      display: flex;
      width: 100%;
      font-size: 0.9rem;

      &__header {
        background: ${({ theme }) => theme.appSecondaryBg};
        border-bottom: none;
      }

      &__current-month {
        color: ${({ theme }) => theme.textColor};
        font-weight: normal;
      }

      &-year-header {
        color: ${({ theme }) => theme.textColor};
        font-weight: normal;
      }

      &__month-container {
        background: ${({ theme }) => theme.appSecondaryBg};
        border-radius: ${({ theme }) => theme.inputBorderRadius};
      }

      &__navigation {
        &--next {
          border-left-color: ${({ theme }) => theme.colorPrimary};
        }

        &--previous {
          border-right-color: ${({ theme }) => theme.colorPrimary};
        }
      }

      &__day-name {
        color: ${({ theme }) => theme.textColorSecondary};
      }

      &__month {
        &-text {
          color: ${({ theme }) => theme.colorPrimary};
          border-radius: 50%;
          border: 1px solid transparent;

          &:hover:not(.react-datepicker__month--disabled),
          &--selected {
            border: 1px solid ${({ theme }) => theme.textColorSelected};
            color: ${({ theme }) => theme.textColorSelected};
            font-weight: normal;
            background: none;
            border-radius: 50%;
          }
        }

        &--in-range {
          border: 1px solid ${({ theme }) => theme.textColorSelected};
          color: ${({ theme }) => theme.textColorSelected};
          font-weight: normal;
          background: none;
          border-radius: 50%;
        }

        &--disabled {
          color: ${({ theme }) => theme.textColorError};
        }
      }

      &__day {
        color: ${({ theme }) => theme.colorPrimary};
        border-radius: 50%;
        border: 1px solid transparent;

        &--keyboard-selected {
          border: 1px solid ${({ theme }) => theme.textColorLink};
          background: none;
          border-radius: 50%;
          font-weight: normal;
        }

        &:hover:not(.react-datepicker__day--disabled),
        &--selected,
        &--in-selecting-range,
        &--in-range {
          border: 1px solid ${({ theme }) => theme.textColorSelected};
          color: ${({ theme }) => theme.textColorSelected};
          font-weight: normal;
          background: none;
          border-radius: 50%;
        }

        &--today {
          border: 1px solid ${({ theme }) => theme.colorPrimary};
          font-weight: normal;
          background: none;
          border-radius: 50%;
        }

        &--disabled {
          color: ${({ theme }) => theme.textColorError};
        }
      }

      &-time__header {
        color: ${({ theme }) => theme.textColor};
        font-weight: normal;
      }

      &__time-container {
        border: none;
      }

      &__time-list {
        scrollbar-color: ${({ theme }) => theme.colorPrimary};

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.colorPrimary};
        }
      }

      &__time {
        background: ${({ theme }) => theme.appSecondaryBg};
        border-radius: ${({ theme }) => theme.inputBorderRadius};

        &-box {
          ul.react-datepicker__time-list {
            background: ${({ theme }) => theme.appSecondaryBg};
            border-radius: ${({ theme }) => theme.inputBorderRadius};
            color: ${({ theme }) => theme.colorPrimary};

            li.react-datepicker__time-list-item {
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                color: ${({ theme }) => theme.textColorSelected};
                background-color: ${({ theme }) => theme.colorSecondary} !important;
              }
            }
          }
        }
      }
    }

    svg {
      position: absolute;
      right: 0.625rem;
      top: ${({ $hasLabel }) => $hasLabel ? '2.325rem' : '0.625rem'};
      color: ${({ theme, $disabled }) => $disabled ? theme.inputInactiveText : theme.textColorClickable};
      display: ${({ $selected }) => $selected ? 'none' : 'block'};
    }

    .react-datepicker-wrapper {
      width: 100%;

      input[type=text] {
        ${InputStyle} /* stylelint-disable-line value-keyword-case */
        width: 100%;
      }

      button {
        padding: 0 0.625rem 0 0;

        ::after {
          background: none;
          padding: 0;
          content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 24 24" fill="none" stroke="${({ theme }) => theme.colorPrimary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
        }
      }
    }
  }
`;

export interface DatePickerProps {
  label?: string;
  disabled?: boolean;
  selected: Date | null | undefined;
  dateFormat?: string | string[];
  timeFormat?: string;
  showTimeSelectOnly?:boolean
  timeIntervals?: number;
  timeCaption?: string;
  showTimeSelect?: boolean;
  isClearable?: boolean;
  placeholderText?: string;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  showMonthYearPicker?: boolean;
  onDateSelect?: (e: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | null) => void;
}

export type DatePickerSelectType = Date | null;

const ThemedDatepicker: React.FC<DatePickerProps> = ({ label, selected, disabled, dateFormat = 'yyyy-MM-dd HH:mm', timeFormat = 'HH:mm', showTimeSelect = true, isClearable = true, placeholderText = 'Select a date', onDateSelect, minDate, maxDate, selectsStart, selectsEnd, startDate, endDate, showMonthYearPicker, ...props }) => {
  const datePickerRef = useRef<DatePicker>(null);

  const setOpen = (open: boolean) => {
    // TODO: Hack to close the datepicker on selected value. Because it's in a container div?
    // https://github.com/Hacker0x01/react-datepicker/issues/1012
    // https://github.com/Hacker0x01/react-datepicker/issues/597
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(open);
    }
  };

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | [Date, Date] | null) => {
    if (!Array.isArray(selected)) {
      return onDateSelect && onDateSelect(e, selected);
    }
  };

  /**
   * check if pressed key is tab
   * @param event keyboard event
   * @returns true if tab, else false
   */
  const isTabKey = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key !== undefined && event?.key === 'Tab') {
      return true;
    } else if (event?.keyCode !== undefined) {
      return event.keyCode === 9;
    } else if (event?.which !== undefined) {
      return event.which === 9;
    } else {
      return false;
    }
  };
  const { showTimeSelectOnly, timeIntervals, timeCaption } = props;
  return (
    <DatePickerContainer $selected={selected instanceof Date} $disabled={disabled} $hasLabel={(label !== undefined && label.length > 0)}>
      {label && <Label name={label} label={label} />}
      <Calendar className='datepicker-icon' size='1.25rem' />
      <DatePicker
        ref={datePickerRef}
        showTimeSelect={showTimeSelect}
        onKeyDown = {(event: React.KeyboardEvent<HTMLDivElement> | undefined) => setOpen(!isTabKey(event))}
        showMonthYearPicker={showMonthYearPicker}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        selected={selected}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        dateFormat={dateFormat}
        onChange={(selected, e) => { setOpen(false); handleChange(e, selected); }}
        timeFormat={timeFormat}
        isClearable={isClearable}
        placeholderText={placeholderText}
      />
    </DatePickerContainer>
  );
};

export default ThemedDatepicker;
