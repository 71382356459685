import React, { useState, useEffect, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownProps as SURDropdownProps } from 'semantic-ui-react';

import Label from './ThemedLabel';

const StyledDropdown = styled(Dropdown)`
  &&&.ui.selection.dropdown {
    background: ${({ theme }) => theme.appBg};
    border-radius: ${({ theme }) => theme.inputBorderRadius};
    border: 2px solid ${({ theme }) => theme.inputBorder};
    color: ${({ theme }) => theme.inputText};
    padding: 0.5rem 2rem 0.5rem 0.5rem;

    &&&.multiple {
      min-height: 3.75rem;
      max-height: none;
    }

    & > .label {
      background: ${({ theme }) => theme.appBg};
      color: ${({ theme }) => theme.colorPrimary};
      border: 2px solid ${({ theme, $loading }) => $loading ? theme.textColorSelected : theme.buttonBorder};
      border-radius: 25px;
    }

    &:focus {
      background: none;
      color: ${({ theme }) => theme.inputText};
      border: ${({ theme }) => `2px solid ${theme.inputBorderSecondary}`};
      outline: none;
    }

    & > .text {
      color: ${({ theme, value }) => !value ? theme.inputPlaceholderText : theme.textColorSelected};
    }

    &.active {
      & > .text {
        color: ${({ theme }) => theme.inputText};
      }

      & > i.icon:not(.clear) {
        transform: scaleY(-1);
        top: 0.6rem;
      }
    }

    .menu {
      background: ${({ theme }) => theme.appBg};
      border-top: 2px solid ${({ theme }) => theme.inputBorder} !important;
      border: 2px solid ${({ theme }) => theme.inputBorder};
      margin: 0 -2px;
      width: calc(100% + 4px);
      color: inherit;

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colorPrimary};
      }

      &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0.1);
      }

      .item {
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .text {
          color: ${({ theme }) => theme.textColorClickable};
        }

        &.selected {
          .text {
            color: ${({ theme }) => theme.textColorSelected};
          }
        }

        &:hover {
          background: ${({ theme }) => theme.colorSecondary};

          .text {
            color: ${({ theme }) => theme.textColorSelected};
          }
        }
      }
    }

    .dropdown.icon {
      ::before {
        width: 1.25rem;
        height: 1.25rem;
        display: block;
      }

      &.clear {
        color: ${({ theme }) => theme.textColorClickable};
        top: ${({ theme }) => theme.sizes.sm};
        right: ${({ theme }) => theme.sizes.sm};
      }

      &:not(.clear) {
        top: 0.313rem;

        ::before {
          content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 24 24" fill="none" stroke="${({ theme }) => theme.colorPrimary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
        }
      }
    }
  }
`;

interface CSSProps {
  $fluid: boolean;
}

const DropdownContainer = styled.div<CSSProps>`
  display: flex;
  flex-flow: column;
  flex: ${({ $fluid }) => $fluid ? 1 : 0};

  input {
    margin: 0;
  }
`;

export type dropdownValueType = string | number | boolean | (string | number | boolean)[] | undefined;

export interface DropdownProps extends SURDropdownProps {
  /**
   * Label for the dropdown
   */
  label?: string;
  required?: boolean;
  name?: string;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  fluid?: boolean;
  /**
   * Indicates if an "All" option should be specified.
   */
  allOption?: boolean;
  /**
   * Value for the "All" option.
   */
  allValue?: number | string;
  value?: dropdownValueType;
}

const ThemedDropdown: React.FC<DropdownProps> = ({ label, required, name, value, onChange, allValue = 0, allOption = false, fluid = false, ...props }) => {
  const [selectedValues, setSelectedValues] = useState<dropdownValueType>();

  useEffect(() => {
    if (value) {
      setSelectedValues(value);
    }
  }, [value]);

  const handleChange = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (allOption) {
      const newData = { ...data };
      let lastItem: (number | string | boolean | undefined);

      if (Array.isArray(data?.value)) {
        [lastItem] = data.value.slice(-1);
      } else {
        lastItem = data?.value;
      }

      const selectedAll = lastItem === allValue;

      if (selectedAll) {
        setSelectedValues([allValue]);
        if (onChange) {
          newData.value = [allValue];
          onChange(e, newData);
        }
        return;
      } else {
        let index = -1;
        let newSelectedValues: (number | string | boolean)[] | undefined;

        if (Array.isArray(data?.value)) {
          index = data?.value?.indexOf(allValue);
          newSelectedValues = [...data.value];
        }

        if (index > -1 && newSelectedValues) {
          newSelectedValues.splice(index, 1);
          setSelectedValues(newSelectedValues);

          if (typeof onChange === 'function') {
            if (Array.isArray(newSelectedValues)) {
              newData.value = newSelectedValues;
            } else {
              newData.value = [newSelectedValues];
            }
            onChange(e, newData);
          }
          return;
        }
      }
    }

    if (onChange) {
      onChange(e, data);
    }
  };

  return (
    <DropdownContainer $fluid={fluid}>
      {label && <Label name={name} label={label} required={required} />}
      <StyledDropdown name={name} $fluid={fluid} onChange={handleChange} value={selectedValues} selection {...props} />
    </DropdownContainer>
  );
};

export default ThemedDropdown;
