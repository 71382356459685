import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, StrictIconProps } from 'semantic-ui-react';

import { ReactComponent as SpinnerComponent } from '../Assets/rings.svg';

const Spinner = styled(SpinnerComponent)`
  stroke: ${({ theme }) => theme.buttonInactiveText};
  width: 2.5rem;
  position: absolute;
  left: 0.45rem;
  top: -0.65rem;
`;

interface ButtonCSSProps {
  $loading: boolean;
  disabled: boolean;
}

export const buttonStyle = css<ButtonCSSProps>`
  border: 2px solid ${({ theme, $loading }) => $loading ? theme.textColorSelected : theme.buttonBorder};
  border-radius: 25px;
  color: ${({ disabled, $loading, theme }) => disabled ? theme.buttonInactiveText : ($loading ? theme.textColorSelected : theme.buttonText)};
  padding: ${({ theme }) => theme.sizes.sm} calc(3 * ${({ theme }) => theme.sizes.sm});
  transition: color ease-in-out 0.1s, background ease-in-out 0.1s;
  background: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  position: ${({ $loading }) => $loading ? 'relative' : ''};
  padding-left: ${({ $loading }) => $loading ? '3rem' : ''};

  svg {
    margin-bottom: -0.313rem;
    margin-top: -0.313rem;
    margin-left: -0.313rem;
    padding-right: ${({ theme }) => theme.sizes.sm};
    color: ${({ theme, disabled }) => disabled ? theme.buttonInactiveText : theme.buttonText};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.buttonInactiveBorder};
    color: ${({ theme }) => theme.buttonInactiveText};
    cursor: default;
  }

  &:active {
    opacity: 0.9;
  }

  & i.icon {
    font-size: 1.2rem;
  }

  &:hover:not(.loading) {
    background: ${({ theme, disabled }) => disabled ? 'none' : theme.colorPrimary};
    color: ${({ theme, disabled }) => disabled ? theme.buttonInactiveText : theme.buttonTextSecondary};

    svg {
      padding-right: ${({ theme }) => theme.sizes.sm};
      color: ${({ theme, disabled }) => disabled ? theme.buttonInactiveText : theme.buttonTextSecondary};
    }
  }
`;

const StyledButton = styled.button`
  ${buttonStyle}  /* stylelint-disable-line value-keyword-case */
`;

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button';
  children?: React.ReactNode[] | React.ReactNode;
  disabled?: boolean;
  icon?: StrictIconProps['name'];
  loading?: boolean;
  onClick?: () => void;
}

const ThemedButton: React.FC<ButtonProps> = ({ children, icon, disabled = false, loading = false, ...props }) => {
  return (
    <StyledButton
      $loading={loading}
      disabled={loading ? true : disabled}
      className={loading ? 'loading' : ''}
      {...props}
    >
      {
        !loading &&
          <>
            {
              icon &&
                <Icon name={icon} />
            }
            {children}
          </>
      }
      {
        loading &&
          <>
            <Spinner />
            Loading
          </>
      }
    </StyledButton>
  );
};

export default ThemedButton;
