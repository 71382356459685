import React, { ReactNode } from 'react';
import { Download } from 'react-feather';
import styled from 'styled-components';

import { writeWorkbook, getExcelRowsFromTableData, getExcelColumnsFromTableData, getExcelColumnFormatsFromTableData } from '../Util/excelHelpers';
import { formatNowDate } from '../Util/formatDate';

import { TableRowType, TableColumnType } from './ThemedTable';

export interface ExportToExcelProps {
  tableDataRows?: TableRowType[];
  tableDataColumns: TableColumnType[];
  getTableDataFunc?: () => Promise<TableRowType[]>;
  sheetName: string;
  fileName?: string;
  loading?: boolean;
  includeSuccessful?: boolean;
}

interface DownloadProps {
  $loading: boolean;
}
const StyledDownloadButton = styled(Download)<DownloadProps>`
  color: ${({ theme, $loading }) => $loading ? theme.inputBorder : theme.colorPrimary};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.inputBorder};
  }
`;

const ExportToExcel: React.FC<ExportToExcelProps> = ({ getTableDataFunc, tableDataRows, tableDataColumns, sheetName, fileName, loading, includeSuccessful }) => {
  const saveAsExcelCSV = (data: ReactNode[]) => {
    const fileNameToSaveAs = fileName ? `${fileName}.xlsx` : `${sheetName} ${formatNowDate()}.xlsx`;
    import('exceljs').then((exceljs) => {
      const wb = new exceljs.Workbook();
      wb.creator = 'Access_Ctrl';
      wb.lastModifiedBy = 'Access_Ctrl';
      wb.created = new Date();
      wb.modified = new Date();
      const ws = wb.addWorksheet(sheetName);

      const columns = getExcelColumnsFromTableData(tableDataColumns, includeSuccessful);

      const tableName = `${sheetName.replace(/ /g, '')}_Table`;
      if (tableDataRows) {
        ws.columns = getExcelColumnFormatsFromTableData(tableDataColumns, data);
      }
      ws.addTable({
        name: tableName,
        ref: 'A1',
        headerRow: true,
        totalsRow: false,
        style: {
          theme: 'TableStyleLight1',
          showRowStripes: false
        },
        columns: columns,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows: (data as any)
      });
      // TODO: Calculate E column from rows instead of hard coded
      ws.addConditionalFormatting({
        ref: `A2:E${data.length + 1}`,
        rules: [
          {
            type: 'expression',
            formulae: ['$D2="Not Successful"'],
            priority: 1,
            style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'fff8410c' }, fgColor: { argb: 'ff000000' } } }
          }
        ]
      });

      ws.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A1' }
      ];

      writeWorkbook(wb, fileNameToSaveAs);
    });
  };
  const onExportToExcel = async () => {
    if (getTableDataFunc) {
      const _tdata = await getTableDataFunc();
      saveAsExcelCSV(getExcelRowsFromTableData(tableDataColumns, _tdata, includeSuccessful));
    } else {
      saveAsExcelCSV(getExcelRowsFromTableData(tableDataColumns, tableDataRows, includeSuccessful));
    }
  };
  return (
    <StyledDownloadButton data-testid='download-excel' onClick={onExportToExcel} $loading={loading ?? true} />
  );
};
export default ExportToExcel;
