import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  color: ${({ theme }) => theme.textColor};
  padding: ${({ theme }) => theme.sizes.md};
`;

interface RowCSSProps {
  $alignItems?: string;
  $spacing?: string;
  $padding?: string;
}

const StyledRow = styled.div<RowCSSProps>`
  align-items: ${({ $alignItems }) => $alignItems};
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-left: -${({ $spacing }) => $spacing};
  padding: ${({ $padding }) => $padding};
  width: calc(100% + ${({ $spacing }) => $spacing});
`;

interface PlaceholderCSSProps {
  $flex?: number;
  $spacing?: string;
}

const Placeholder = styled.div<PlaceholderCSSProps>`
  display: flex;
  flex: ${({ $flex }) => $flex || 1};
  padding-left: ${({ $spacing }) => $spacing};
`;

interface SectionCSSProps {
  $flexGrow?: boolean;
  $minWidth?: string;
  $minHeight?: string;
}

const StyledSection = styled.section<SectionCSSProps>`
  display: flex;
  flex-flow: column;
  flex: ${({ $flexGrow }) => $flexGrow ? 1 : 0};
  min-width: ${({ $minWidth }) => $minWidth || '25rem'};
  min-height: ${({ $minHeight }) => $minHeight || ''};
`;

// Modal

const ModalWrapper = styled.div`
  background: ${({ theme }) => theme.appBg};
  color: ${({ theme }) => theme.textColor};

  .content,
  .header {
    background: ${({ theme }) => theme.appBg};
  }
`;

const StyledModalHeader = styled.div`
  background: ${({ theme }) => theme.appBg};
  padding: ${({ theme }) => theme.sizes.md};
  border-top-left-radius: ${({ theme }) => theme.inputBorderRadius};
  border-top-right-radius: ${({ theme }) => theme.inputBorderRadius};
`;

export interface ModalHeaderProps {
  children: React.ReactNode[] | React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, ...props }) => (<StyledModalHeader {...props}><h2>{children}</h2></StyledModalHeader>);

const ModalContent = styled.div`
  background: ${({ theme }) => theme.appBg};
`;

const ModalButtonContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.sizes.md};
`;

export interface WrapperProps {
  children: React.ReactNode[] | React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children, ...props }) => (<StyledWrapper {...props}>{children}</StyledWrapper>);

export interface RowProps {
  children: React.ReactNode[] | React.ReactNode;
  spacing?: string;
  padding?: string;
  alignItems?: string;
  className?: string;
  childFlex?: number[];
}

const Row: React.FC<RowProps> = ({ children, spacing, padding, alignItems, className, childFlex = [] }) => {
  return (
    <StyledRow
      className={className}
      $spacing={spacing}
      $padding={padding}
      $alignItems={alignItems}
    >
      {React.Children.map(children || null, (child, i) => {
        if (!React.isValidElement(child)) {
          return;
        }

        return (
          <Placeholder key={i} $spacing={spacing} $flex={childFlex[i]}>
            <child.type {...child.props} />
          </Placeholder>
        );
      })}
    </StyledRow>
  );
};

export interface SectionProps {
  children: React.ReactNode[] | React.ReactNode;
  flexGrow?: boolean;
  minWidth?: string;
  minHeight?: string;
}

const Section: React.FC<SectionProps> = ({ children, flexGrow = true, minWidth, minHeight, ...props }) => (<StyledSection $flexGrow={flexGrow} $minWidth={minWidth} $minHeight={minHeight} {...props}>{children}</StyledSection>);

const Layout = {
  Wrapper,
  Row,
  Section,
  Modal: {
    Wrapper: ModalWrapper,
    Header: ModalHeader,
    Content: ModalContent,
    ButtonContainer: ModalButtonContainer
  }
};

export default Layout;
