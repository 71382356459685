import React from 'react';
import styled from 'styled-components';
import { Info } from 'react-feather';

import Popup from './ThemedPopup';

const StyledInfo = styled(Info)`
  color: ${({ theme }) => theme.textColorClickable};
  width: 1.71428571rem;
  height: 1.71428571rem;
`;

const HitArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export interface InfoPopupProps {
  descriptionText: string;
}

const InfoPopup: React.FC<InfoPopupProps> = ({ descriptionText }) => {
  return (
    <Popup
      className='popupStyle'
      content={descriptionText}
      trigger={
        <HitArea>
          <StyledInfo />
        </HitArea>
      }
    />
  );
};

export default InfoPopup;
