import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';
import type { CheckboxProps } from 'semantic-ui-react';

const StyledCheckbox = styled(Checkbox)`
  &&&.ui.checkbox {
    input {
      + label {
        color: ${({ theme }) => theme.textColor};
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding-left: 2.2rem;
        white-space: nowrap;

        ::before {
          background: none;
          content: '';
          display: block;
          border: 2px solid ${({ theme }) => theme.colorPrimary};
          border-radius: 0.188rem;
          width: 1.7rem;
          height: 1.7rem;
        }
      }
    }

    &.checked {
      input {
        &:checked {
          + label {
            ::after {
              content: '';
            }

            ::before {
              background: none;
              border: none;
              content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" viewBox="0 0 24 24" fill="none" stroke="${({ theme }) => theme.colorPrimary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>');
            }
          }
        }
      }
    }
  }
`;

const ThemedCheckbox: React.FC<CheckboxProps> = (props) => {
  return (<StyledCheckbox {...props} />);
};

export default ThemedCheckbox;
export type { CheckboxProps };
