import React from 'react';
import styled from 'styled-components';
import { Ref, TextArea, TextAreaProps as SURTextAreaProps } from 'semantic-ui-react';

import { InputStyle } from './ThemedInput';

const Container = styled.div`
  height: 100%;

  && textarea {
    ${InputStyle}  /* stylelint-disable-line value-keyword-case */
    height: inherit;

    /* TODO: Fix scrollbar cursor when https://bugs.chromium.org/p/chromium/issues/detail?id=1032055 is fixed */
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colorPrimary};
    }
  }
`;

export interface TextAreaProps extends SURTextAreaProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _ref?: any; // TODO: Disabled rule
}

// TODO: Update this once https://github.com/Semantic-Org/Semantic-UI-React/issues/3819 has been resolved
const TextAreaWithRef: React.FC<TextAreaProps> = ({ _ref, ...props }) => (
  <Container>
    <Ref innerRef={_ref}>
      <TextArea {...props} />
    </Ref>
  </Container>
);

export default TextAreaWithRef;
