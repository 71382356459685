import React from 'react';
import styled, { css } from 'styled-components';
import { User, Box, Monitor, BarChart2, List } from 'react-feather';
import { Link } from 'react-router-dom';

import { IconTypes } from '../Common/Constant/IconType';
import type { TableIconType } from '../Common/Constant/IconType';

const StyledTableLink = styled.div`
  display: flex;
  align-items: center;
`;

interface TableIconCSSProps {
  $clickable?: boolean;
  $successful?: boolean;
  size?: string;
}

const tableIconStyles = css<TableIconCSSProps>`
  cursor: ${({ $clickable }) => $clickable ? 'pointer' : 'normal'};
  color: ${({ $successful, theme }) => $successful ? theme.textColor : theme.textColorError};
  min-width: ${({ size }) => size};
  padding-right: ${({ theme }) => theme.sizes.md};
`;

const StyledUser = styled(User)`
  ${tableIconStyles}  /* stylelint-disable-line value-keyword-case */
`;

const StyledDashboard = styled(Monitor)`
  ${tableIconStyles}  /* stylelint-disable-line value-keyword-case */
`;

const StyledDevice = styled(Box)`
  ${tableIconStyles}  /* stylelint-disable-line value-keyword-case */
`;

const StyledGraph = styled(BarChart2)`
  ${tableIconStyles};  /* stylelint-disable-line value-keyword-case */
  color: ${({ theme }) => theme.colorPrimary};
`;

const StyledList = styled(List)`
  ${tableIconStyles};  /* stylelint-disable-line value-keyword-case */
  color: ${({ theme }) => theme.colorPrimary};
`;

interface StyledLinkCSSProps {
  $successful?: boolean;
  $strikeThrough?: boolean;
  $fontSize?: string;
}

const StyledLink = styled(Link)<StyledLinkCSSProps>`
  &&& {
    color: ${({ $successful, theme }) => $successful ? theme.textColorLink : theme.textColorError};
    text-decoration: ${({ $strikeThrough }) => $strikeThrough ? 'line-through' : 'none'};
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: ${({ $fontSize }) => $fontSize};

    &:hover {
      color: ${({ $successful, theme }) => $successful ? theme.textColorLinkHover : theme.textColorErrorHover};
      text-decoration: ${({ $strikeThrough }) => $strikeThrough ? 'line-through' : 'none'};
    }
  }
`;

export const getLinkIcon = (iconType: TableIconType, successful: boolean | undefined, onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void): React.ReactNode | null => {
  switch (iconType) {
    case IconTypes.Device:
      return <StyledDevice $clickable={onClick !== undefined} onClick={onClick} $successful={successful} size='2.2rem' />;
    case IconTypes.User:
      return <StyledUser $clickable={onClick !== undefined} onClick={onClick} $successful={successful} size='2.2rem' />;
    case IconTypes.Dashboard:
      return <StyledDashboard $clickable={onClick !== undefined} onClick={onClick} $successful={successful} size='2.2rem' />;
    case IconTypes.Graph:
      return <StyledGraph $clickable={onClick !== undefined} onClick={onClick} $successful={successful} size='2.2rem' />;
    case IconTypes.List:
      return <StyledList $clickable={onClick !== undefined} onClick={onClick} $successful={successful} size='2.2rem' />;
    default:
      return null;
  }
};

export interface TableLinkProps {
  strikeThrough?: boolean;
  iconType?: TableIconType;
  text?: string;
  to?: string;
  successful?: boolean;
  fontSize?: string;
}

const TableLink: React.FC<TableLinkProps> = ({ strikeThrough, iconType, text, to, successful = true, fontSize = '1rem' }) => {
  return (
    <StyledTableLink>
      {iconType !== undefined &&
        getLinkIcon(iconType, successful)}
      <StyledLink to={to} title={text} $strikeThrough={strikeThrough} $successful={successful} $fontSize={fontSize}>
        {text}
      </StyledLink>
    </StyledTableLink>
  );
};

export default TableLink;
