import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Search, Icon } from 'semantic-ui-react';

import Label from './ThemedLabel';
import { InputStyle } from './ThemedInput';

interface ResultRenderProps {
  title: string;
}

const ResultRenderer: React.FC<ResultRenderProps> = ({ title }) => (
  <ResultDiv>
    {title}
  </ResultDiv>
);

const ResultDiv = styled.div`
  color: ${({ theme }) => theme.colorPrimary};
`;

const StyledFormField = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  .ui.input {
    display: flex;
    flex-flow: column;
    flex: 1;

    & .icon {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }

  .ui.input > input[type=text] {
    ${InputStyle} /* stylelint-disable-line value-keyword-case */
  }
`;

const StyledSearch = styled(Search)`
  &&& .results {
    overflow-y: auto;
    max-height: 18.75rem;
    width: 100%;
  }

  &&& .results > .message {
    background-color: ${({ theme }) => theme.appSecondaryBg};
    border-radius: 0.28571429rem;

    & > div {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }

  &&& .results > .result {
    background-color: ${({ theme }) => theme.appSecondaryBg};

    &.active {
      background-color: ${({ theme }) => theme.appBg};

      & > div {
        color: ${({ theme }) => theme.inputText};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.appBg};

      & > div {
        color: ${({ theme }) => theme.inputText};
      }
    }
  }

  &&& .results > .result:only-child {
    border-radius: 0.28571429rem;
  }
`;

export interface SearchValues {
  name: string;
  id: string;
}

export interface ResultValues {
  title: string;
  description: string;
}

export interface SearchProps {
  fetchingSource?: boolean;
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  openOnFocus?: boolean;
  clearable?: boolean;
  required?: boolean;
  fluid?:boolean;
  noResultsMessage?:string;
  noResultsDescription?:string;
  searchValues: (string | SearchValues)[];
  onChange: (title: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
  onResultSelect?: (item: SearchValues, name?: string) => void;
  result?: ResultValues;
}

const ThemedSearch: React.FC<SearchProps> = ({ fetchingSource, searchValues, onChange, name, label, required, value, onResultSelect, clearable, openOnFocus, ...props }) => {
  const [results, setResults] = useState<ResultValues[]>([]);
  const [source, setSource] = useState<ResultValues[]>([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const newSource = searchValues.map((val: string | SearchValues) => {
      function isSearchValues(val: string | SearchValues): val is SearchValues {
        return (val as SearchValues).name !== undefined;
      }

      if (isSearchValues(val)) {
        return {
          title: val.name,
          description: val.id
        };
      } else {
        return {
          title: val,
          description: val
        };
      }
    });

    setSource(newSource);
  }, [searchValues]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result: ResultValues) => re.test(result.title);

    setResults(_.filter(source, isMatch));
  }, [value, source]);

  const handleResultSelect = (_e: React.SyntheticEvent<HTMLElement, Event>, data:SearchProps) => {
    const { name, result } = data;
    if (result) {
      setResults([result]);
      onChange(result.title);
      setOpen(false);
      if (onResultSelect) onResultSelect({ name: result.title, id: result.description }, name);
    }
  };

  const handleFocus = () => {
    if (value === '' && openOnFocus) {
      setOpen(true);
    } else if (value !== '') {
      setOpen(true);
    }
  };

  const handleBlur = () => {
    if (value === '') {
      setOpen(false);
    } else if (value !== '') {
      setOpen(false);
    }
  };

  const handleSearchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onChange(value, event);
    setOpen(true);
  };

  const handleClearClick = () => {
    setOpen(false);
    onChange('');
  };

  const getSearchIcon = () => {
    // TODO: use react-feather icons?
    if (clearable && value !== '') {
      return <Icon name='delete' link onClick={handleClearClick} />;
    }

    return <Icon name='search' />;
  };

  return (
    <StyledFormField>
      {label && <Label name={name} label={label} required={required} />}
      <StyledSearch
        icon={getSearchIcon()}
        id={name}
        name={name}
        loading={fetchingSource}
        onResultSelect={handleResultSelect}
        onSearchChange={_.debounce(handleSearchChange, 500, {
          leading: true
        })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        open={open}
        results={results}
        value={value}
        resultRenderer={ResultRenderer}
        selectFirstResult
        {...props}
      />
    </StyledFormField>
  );
};

export default ThemedSearch;
