import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface CSSProps {
  $fluid?: boolean;
}

export const InputStyle = css<CSSProps>`
  background: none;
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  border: 2px solid ${({ theme }) => theme.inputBorder};
  color: ${({ theme }) => theme.inputText};
  flex: ${({ $fluid }) => $fluid ? 1 : 0};
  height: 2.5rem;
  padding: ${({ theme }) => theme.sizes.sm};

  &:focus {
    background: none;
    color: ${({ theme }) => theme.inputText};
    border: ${({ theme }) => '2px solid ' + theme.inputBorderSecondary};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.inputInactiveBorder};
    color: ${({ theme }) => theme.inputInactiveText};
    background: ${({ theme }) => theme.inputInactiveBackground};
  }

  &::selection {
    background-color: ${({ theme }) => theme.inputText};
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledInput = styled.input`
  &,
  &&&:not([type]),
  &&&[type=password],
  &&&[type=text],
  &&&[type=number] {
    ${InputStyle} /* stylelint-disable value-keyword-case */
  }
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'password' | 'text' | 'number';
  fluid?: boolean;
}

const ThemedInput: React.FC<InputProps> = ({ type = 'text', fluid, ...props }) => <StyledInput type={type} $fluid={fluid} {...props} />;

export default ThemedInput;
