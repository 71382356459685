import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionProps as SURAccordionProps, AccordionTitle, AccordionContent } from 'semantic-ui-react';
import { Triangle } from 'react-feather';

import InfoPopup from './InfoPopup';

const StyledAccordionContainer = styled.div`
  padding: ${({ theme }) => theme.sizes.md};
`;

const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.appBorderColor};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
`;

const StyledAccordionTitle = styled(AccordionTitle)`
  &&&.styledAccordionTitle.title {
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    display: flex;
    font-size: calc(3 * ${({ theme }) => theme.sizes.sm});
    font-weight: normal;
    justify-content: space-between;
    padding: ${({ theme }) => theme.sizes.md};

    & > .wrapper-span {
      justify-content: flex-start;
      display: flex;
      align-items: center;
    }
  }
`;

const StyledAccordionContent = styled(AccordionContent)`
  padding: ${({ theme }) => theme.sizes.md} !important;

  .clearButtonContainer {
    display: flex;
    justify-content: flex-end;
  }

  .childrenContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

interface TriangleCSSProps {
  $active?: boolean;
}

const StyledTriangle = styled(Triangle)<TriangleCSSProps>`
  color: ${({ theme }) => theme.textColorClickable};
  transition: transform ease-in-out 0.15s;
  transform: ${({ $active }) => $active ? 'rotate(-59deg)' : 'rotate(0)'};
`;

export interface AccordionProps extends SURAccordionProps {
  children: React.ReactNode[] | React.ReactNode;
  title?: string;
  activated?: boolean;
  infoPopupText?: string;
}

const ThemedAccordion: React.FC<AccordionProps> = ({ children, title, activated, infoPopupText = null, ...props }) => {
  const [active, setActive] = useState(activated);

  return (
    <StyledAccordionContainer>
      <StyledAccordion {...props} className='styledAccordion'>
        <StyledAccordionTitle className='styledAccordionTitle' onClick={() => setActive(!active)}>
          <span className='wrapper-span'>
            <h2>{title}</h2>
            {infoPopupText && <InfoPopup descriptionText={infoPopupText} />}
          </span>
          <div className='iconContainer'>
            <StyledTriangle $active={active} size='1.5rem' />
          </div>
        </StyledAccordionTitle>
        <StyledAccordionContent className='styledAccordionContent' active={active}>
          {children}
        </StyledAccordionContent>
      </StyledAccordion>
    </StyledAccordionContainer>
  );
};

export default ThemedAccordion;
