import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Checkbox from './ThemedCheckbox';
import { CheckboxProps } from 'semantic-ui-react';

interface CSSProps {
  $disabled: boolean;
}

const StyledSegment = styled.div<CSSProps>`
  background: ${({ theme, $disabled }) => $disabled ? theme.inputInactiveBackground : ''};
  display: flex;
  flex-flow: column;
  position: relative;
`;

const StyledHeader = styled.div`
  background: ${({ theme }) => theme.appBg};
  padding: ${({ theme }) => theme.sizes.md};
`;

export interface ToggleSegmentProps {
  toggled?: boolean;
  header: string;
  children: React.ReactNode[] | React.ReactNode;
  onToggleChange?: (e: React.SyntheticEvent<HTMLElement, Event>, data: CheckboxProps) => void;
  hideChildren?: boolean;
}

const ToggleSegment: React.FC<ToggleSegmentProps> = ({ toggled = false, header, children, onToggleChange, hideChildren = false }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(toggled);
  }, [toggled]);

  const recursiveMap = (children: React.ReactNode | React.ReactNode[], fn: (child: React.ReactNode) => void): React.ReactNode => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children, fn)
        });
      }

      return fn(child);
    });
  };

  const renderChildren = () => {
    const childrenWithProps = recursiveMap(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { disabled: !checked });
      }

      return null;
    });

    return childrenWithProps;
  };

  return (
    <>
      {
        header &&
          <StyledHeader>
            <Checkbox onChange={onToggleChange} onClick={() => setChecked(!checked)} checked={checked} label={header} />
          </StyledHeader>
      }
      {
        ((hideChildren && checked) || (!hideChildren)) &&
          <StyledSegment $disabled={!checked}>
            {renderChildren()}
          </StyledSegment>
      }
    </>
  );
};

export default ToggleSegment;
