import { DateTime } from 'luxon';

export const DATE_FORMAT = 'kkkk-LL-dd HH:mm:ss';

export const convertDate = (date:string) => {
  if (!date) {
    return DateTime.fromMillis(0);
  }

  return DateTime.fromISO(date, { zone: 'utc' });
};

export const formatDate = (date:string) => {
  return convertDate(date).toLocal().toFormat(DATE_FORMAT);
};

export const formatNowDate = () => {
  return DateTime.local().toFormat(DATE_FORMAT);
};
