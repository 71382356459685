import React from 'react';
import styled from 'styled-components';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizes.sm};
`;

const Label = styled.label`
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  line-height: 1.4285em;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textColorSecondary};
  margin-right: ${({ theme }) => theme.sizes.md};
`;

const StyledRequired = styled.div`
  font-size: 0.75rem;
  line-height: 1.4285em;
  color: ${({ theme }) => theme.textColor};
`;

const Required: React.FC = () => (
  <StyledRequired>
    Required
  </StyledRequired>
);

export interface LabelProps {
  label: string;
  name?: string;
  required?: boolean;
}

const ThemedLabel: React.FC<LabelProps> = ({ label, name, required }) => (
  <LabelContainer>
    {label &&
      <Label htmlFor={name}>{label}</Label>}
    {required &&
      <Required />}
  </LabelContainer>
);

export default ThemedLabel;
