import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import type { FormProps } from 'semantic-ui-react';

const StyledForm = styled(Form)`
  &&& .required.field > label::after {
    font-size: 0.75rem;
    line-height: 1.4285em;
    color: ${({ theme }) => theme.textColor};
    text-transform: none;
    content: 'Required';
    margin-left: ${({ theme }) => theme.sizes.md};
    margin-top: 0;
    margin-bottom: 0;
  }

  &&& .field.error > label {
    color: ${({ theme }) => theme.textColorError};
  }

  &&& .field.error > input {
    color: ${({ theme }) => theme.textColorError};
    background-color: ${({ theme }) => theme.appBg};
    border-color: ${({ theme }) => theme.textColorErrorSecondary};

    &:focus {
      color: ${({ theme }) => theme.textColorError};
      background-color: ${({ theme }) => theme.appBg};
      border-color: ${({ theme }) => theme.textColorError};
    }

    &::selection {
      color: ${({ theme }) => theme.appBg};
    }
  }

  &&& .error.field .prompt.label {
    color: ${({ theme }) => theme.textColorError} !important;
    border-color: ${({ theme }) => theme.textColorErrorSecondary} !important;
  }
`;

const ThemedForm: React.FC<FormProps> = ({ children, ...props }) => <StyledForm {...props}>{children}</StyledForm>;

export default ThemedForm;

export type { FormProps };
