import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'semantic-ui-react';
import type { PaginationProps } from 'semantic-ui-react';

const StyledPagination = styled(Pagination)`
  &&& {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;

    &&& a {
      color: ${({ theme }) => theme.textColorClickable};
      padding: 0;
      min-width: 0;
      height: calc(1rem + ${({ theme }) => theme.sizes.md});
      width: calc(1rem + ${({ theme }) => theme.sizes.md});
      margin-right: ${({ theme }) => theme.sizes.sm};
      margin-left: ${({ theme }) => theme.sizes.sm};
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        color: ${({ theme }) => theme.textColorSelected};
        border: 1px solid ${({ theme }) => theme.textColorSelected};
        border-radius: 100%;
      }

      &::before {
        background: none;
        width: 0;
      }
    }
  }
`;

const ThemedPagination: React.FC<PaginationProps> = ({ totalPages = 1, ellipsisItem = null, firstItem = null, lastItem = null, ...props }) => {
  return (
    <StyledPagination
      totalPages={totalPages}
      ellipsisItem={ellipsisItem}
      firstItem={firstItem}
      lastItem={lastItem}
      {...props}
    />
  );
};

export default ThemedPagination;

export type { PaginationProps };
