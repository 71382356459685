import React from 'react';
import { Confirm, ConfirmProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { buttonStyle } from './ThemedButton';

const StyledConfirm = styled(Confirm)`
  color: ${({ theme }) => theme.textColor};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &&& .header {
    color: ${({ theme }) => theme.textColor};
    background: ${({ theme }) => theme.appSecondaryBg};
    padding: ${({ theme }) => theme.sizes.md};
    border-top-left-radius: ${({ theme }) => theme.inputBorderRadius};
    border-top-right-radius: ${({ theme }) => theme.inputBorderRadius};

    &:not(.ui) {
      font-size: 1.5rem;
    }
  }

  &&& .content {
    background: ${({ theme }) => theme.appBg};
  }

  &&& .actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    justify-content: space-between;
    border-top: 0;
    background: ${({ theme }) => theme.appBg};
    padding: ${({ theme }) => theme.sizes.md};
  }

  &&& .ui.button {
    ${buttonStyle}  /* stylelint-disable-line value-keyword-case */
  }

  &&& .close.icon {
    position: absolute;
    top: 2px;
    right: 0;
    color: ${({ theme }) => theme.colorPrimary};
    font-size: 15px;
  }
`;
export const ThemedConfirm:React.FC<ConfirmProps> = ({ ...props }) => {
  return (
    <StyledConfirm closeIcon {...props} />
  );
};

export default ThemedConfirm;
