import React, { useState } from 'react';
import styled from 'styled-components';
import { Eye, EyeOff } from 'react-feather';

import Input from './ThemedInput';
import type { InputProps } from './ThemedInput';
import Label from './ThemedLabel';

interface CSSProps {
  $hasLabel?: boolean;
  $disabled?: boolean;
}

const PasswordInputContainer = styled.div<CSSProps>`
  position: relative;
  flex: 1;
  display: inline-block;

  input {
    margin: 0;
  }

  svg {
    margin-left: ${({ theme }) => theme.sizes.sm};
    position: absolute;
    right: 0.625rem;
    top: ${({ $hasLabel }) => $hasLabel ? '2.325rem' : '0.625rem'};
    color: ${({ theme, $disabled }) => $disabled ? theme.inputInactiveText : theme.textColorClickable};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export interface PasswordInputProps extends InputProps {
  placeholder?: string;
  label?: string;
  name?: string;
  autoComplete?: string;
  required?: boolean;
  disabled?: boolean;
  defaultHidden?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ placeholder = 'Password', label, name, autoComplete, required, disabled, defaultHidden = true, ...props }) => {
  const [hidden, setHidden] = useState(defaultHidden);

  const handleHiddenToggle = () => {
    if (!disabled) {
      setHidden(!hidden);
    }
  };

  return (
    <>
      <PasswordInputContainer $disabled={disabled} $hasLabel={(label !== undefined && label.length > 0)}>
        {label && <Label name={name} label={label} required={required} />}
        {hidden &&
          <Eye
            size='1.25rem'
            onClick={handleHiddenToggle}
          />}
        {!hidden &&
          <EyeOff
            size='1.25rem'
            onClick={handleHiddenToggle}
          />}
        <InputWrapper>
          <Input
            {...props}
            id={name}
            name={name}
            autoComplete={autoComplete}
            disabled={disabled}
            type={hidden ? 'password' : 'text'}
            placeholder={placeholder}
            fluid
          />
        </InputWrapper>
      </PasswordInputContainer>
    </>
  );
};

export default PasswordInput;
