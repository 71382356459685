import React from 'react';
import { Modal, ModalProps as SURModalProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemedConfirm as ConfirmModal } from './ThemedConfirm';

const StyledModal = styled(Modal)`
  &&& {
    .close {
      top: 0;
      right: 0;

      &::before {
        content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" stroke="rgb(239,124,0)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
      }
    }
  }
`;

export interface ModalProps extends SURModalProps {
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
  confirmClose?: () => void;
  showConfirmModal: boolean;
  setShowConfirmModal?: (state: boolean) => void;
  confirmOnCloseButtonClick?: boolean;
}
// TODO build project export it to tripleadmin and change the modals in tripleadmin to utulize it
const ThemedModal: React.FC<ModalProps> = ({ children, ...props }) => {
  const confirmOnCloseButtonClick = props.confirmOnCloseButtonClick || false;
  const { confirmClose, showConfirmModal, setShowConfirmModal } = props;

  const handleConfirmClose = () => {
    if (confirmClose) {
      confirmClose();
    }
  };
  const handleCancelClose = () => {
    if (setShowConfirmModal) {
      setShowConfirmModal(false);
    }
  };

  const handleCloseIconClick = () => {
    if (confirmClose && !showConfirmModal) {
      confirmClose();
    }
    if (confirmClose && setShowConfirmModal) {
      confirmClose();
      setShowConfirmModal(false);
    }
  };

  const handleModalClick = (event:React.MouseEvent<HTMLElement>) => {
    const elementClassName = (event.target as HTMLElement)?.className;
    // If we're not able to find classname we don't care about click
    if (Object.prototype.toString.call(elementClassName) !== '[object String]') {
      return;
    }
    if (elementClassName && elementClassName?.includes('close icon') && !confirmOnCloseButtonClick) {
      handleCloseIconClick();
    }
  };

  return (
    <>
      <StyledModal {...props} onClick={handleModalClick} size='large'>
        {children}
      </StyledModal>
      <ConfirmModal
        open={showConfirmModal}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
        header={'Are you sure?'}
        content="Are you sure you want to close?"
        size='tiny'
      />
    </>
  );
};

export default ThemedModal;
