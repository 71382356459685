import React, { useState } from 'react';
import styled from 'styled-components';
import { Message, Divider } from 'semantic-ui-react';

import Accordion from './ThemedAccordion';
import Input from './ThemedInput';
import Label from './ThemedLabel';
import Button from './ThemedButton';

const DangerZoneInputWrapper = styled.div`
  input {
    width: 100%;
  }
`;

const StyledDivider = styled(Divider)`
  &&&.ui.divider {
    color: ${({ theme }) => theme.textColorError};
  }
`;

const StyledMessage = styled(Message)`
  & > p {
    display: inline-block;
  }

  &&&.ui.negative.message {
    background-color: ${({ theme }) => theme.appBg};
    color: ${({ theme }) => theme.textColorError};

    & > div.header {
      color: ${({ theme }) => theme.textColorError};
    }
  }
`;
const DangerZoneButton = styled(Button)`
  min-width: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DangerZoneItemContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  button: {
    width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export interface DangerZoneItemProps {
  buttonText: string;
  onClick: () => void;
  headerText: string;
  detailsText: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const DangerZoneItem: React.FC<DangerZoneItemProps> = ({ buttonText, onClick, icon, headerText, detailsText, disabled }) => {
  return (
    <StyledMessage negative>
      <Message.Header>{headerText}</Message.Header>
      <DangerZoneItemContainer>
        <p>{detailsText}</p>
        <DangerZoneButton disabled={disabled} onClick={onClick}>{icon}{buttonText}</DangerZoneButton>
      </DangerZoneItemContainer>
    </StyledMessage>
  );
};

export interface DangerZoneProps {
  infoPopupText?: string;
  unlockText: string;
  placeholder: string;
  children?: React.ReactElement<DangerZoneItemProps>[] | React.ReactElement<DangerZoneItemProps>;
  dividerText?: string;
  activated?: boolean
}

const DangerZone: React.FC<DangerZoneProps> = ({ activated, infoPopupText, unlockText, dividerText = 'Dangerous actions', placeholder, children }) => {
  const [dangerZoneUnlockText, setDangerZoneUnlockText] = useState('');

  const handleUnlockInputChange = (val: string) => {
    setDangerZoneUnlockText(val);
  };

  const renderChildren = () => {
    const zoneIsUnlocked = dangerZoneUnlockText.toUpperCase() === unlockText.toUpperCase();

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { disabled: !zoneIsUnlocked });
      }

      return child;
    });

    return childrenWithProps;
  };

  return (
    <>
      <Accordion activated={activated} title='Danger zone' infoPopupText={infoPopupText}>
        <DangerZoneInputWrapper>
          <Label name={placeholder} label={placeholder} />
          <Input fluid onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUnlockInputChange(e.target.value)} placeholder={placeholder} />
        </DangerZoneInputWrapper>
        <StyledDivider horizontal>{dividerText}</StyledDivider>
        {renderChildren()}
      </Accordion>
    </>
  );
};

export default DangerZone;
